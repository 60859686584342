.step-outer-wrapper {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}

.step-inner-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 0px;
  margin-right: 0px;
}

.number-bullet {
  height: 25px;
  width: 25px;
  min-width: 25px;
  background: var(--brandColour1);
  border-radius: 50%;
  text-align: center;
  display: flex;
  margin: auto;
}

.number-bullet-text {
  margin: auto;
  font-family: "SF Pro Text", Sans-Serif;
  font-weight: 500;
  font-size: 12px;
  color: white;
}

.number-bullet-inverted {
  height: 25px;
  width: 25px;
  min-width: 25px;
  background: #fff;
  border: 1px solid var(--brandColour1);
  border-radius: 50%;
  text-align: center;
  display: flex;
  margin: auto;
}

.number-bullet-text-inverted {
  margin: auto;
  font-family: "SF Pro Text", Sans-Serif;
  font-weight: 500;
  font-size: 12px;
  color: var(--brandColour1);
}

.step-line-inner-wrapper {
  height: 25px;
}

.step-line {
  height: 2px;
  margin-top: 12.5px;
  margin-bottom: auto;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  border: 0px;
  background-color: var(--brandColour1);
}

.step-text {
  margin: auto;
  margin-top: 5px;
  font-family: "SF Pro Text", Sans-Serif;
  font-weight: 400;
  font-size: 11px;
  color: var(--UIcolour2);
}
