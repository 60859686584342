.landing-cover-image-wrapper {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 77px;
  margin-bottom: 15px;
  text-align: center;
  max-width: 960px;
}

.landing-cover-outer-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing-cover-mid-container {
  margin: auto;
}

.landing-cover-inner-container {
  margin: auto;
}

.landing-cover-image-and-logo-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing-cover-image-container {
  margin: auto;
}

.landing-cover-content-container {
  margin: auto;
}

.landing-cover-image {
  margin: auto;
  height: auto;
  width: 100%;
  max-width: 450px;
  max-height: 450px;
}

.landing-partner-logo {
  margin: auto;
  height: auto;
  width: 100%;
  max-width: 180px;
}

.landing-h1 {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.landing-h2 {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 130%;
  color: #6a6a6a;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing-cta-button-wrapper {
  margin: auto;
  margin-left: 5px;
  display: flex;
  justify-content: center;
}

.landing-cta-button {
  -webkit-appearance: none;
  background-color: #1ec1a1;
  width: 260px;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 18px;
  color: white;
  text-align: center;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.landing-cta-button:hover,
.landing-cta-button:focus {
  background-color: #22ba9d;
  border: none;
  outline: 0;
}

.landing-link-button {
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
  max-width: 280px;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 17px;
  cursor: pointer;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}

.landing-link-button:hover {
  text-decoration: underline;
  opacity: 0.75;
}

.landing-link-button:focus {
  outline: 0;
  opacity: 0.75;
}

@media only screen and (min-width: 650px) {
  .landing-cover-inner-container {
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    flex-wrap: nowrap;
  }
  .landing-cover-image-container {
    margin: auto;
    max-width: 450px;
    max-height: 450px;
  }
  .landing-cover-content-container {
    margin-left: 15px;
  }
  .landing-cover-content-container h1 {
    text-align: left;
  }
  .landing-cover-content-container h2 {
    text-align: left;
  }
  .landing-cover-content-container .landing-cta-button {
    margin-left: 15px;
    margin-right: auto;
  }

  .landing-cover-content-container .landing-cta-button-wrapper {
    justify-content: start;
  }

  .landing-link-button {
    margin-left: 20px;
  }
}
