.infopage-image {
  margin-left: 0;
  margin-top: 0px;
  margin-bottom: 15px;
  border-radius: var(--UIborderRadius);
  width: 100%;
  max-width: 300px;
  height: 150px;
  object-fit: cover;
}

.infopage-subheading {
  margin: auto;
  margin-top: 15px;
  margin-bottom: 30px;
}

.infopage-link-wrapper {
  margin: auto;
  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.infopage-text-and-button-wrapper {
  margin: auto;
  margin-top: 15px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.infopage-link-heading {
  margin: 0;
  margin-right: 5px;
  line-height: 125%;
}

.infopage-link-heading {
  margin: 0;
  margin-right: 5px;
  line-height: 125%;
}
