.iframe-phone-container {
  margin: auto;
  margin-top: 0px;
  margin-right: 0px;
  position: relative;
  width: 259px;
  height: 500px;
}

@media (max-width: 650px) {
  .iframe-phone-container {
    margin-left: 0;
    margin-right: auto;
    margin-top: 30px;
  }
}

.iframe-phone-background {
  width: 259px;
  height: 500px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.iframe-phone-screen {
  margin: auto;
  margin-top: 0px;
  padding: 0px;
  width: 205px;
  height: 433px;
  position: absolute;
  top: 31px;
  left: 40px;
  z-index: 3;
  border-radius: 4px 4px 30px 30px;
}

.view-fullscreen-cta-button-wrapper {
  margin-left: 84px;
  margin-right: auto;
}

@media (max-width: 650px) {
  .view-fullscreen-cta-button-wrapper {
    margin-left: 45px;
    margin-right: auto;
  }
}
