.headerbar {
  background-color: #ffffff;
  height: 62px;
  border-bottom: 1px solid #cccccc;
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

.headerbar a {
  width: auto;
  height: 36px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.header-logo {
  width: auto;
  height: 36px;
  margin: auto;
  cursor: pointer;
}

.header-button-and-avatar-container {
  margin: 0;
  display: flex;
}

.header-profile-image {
  width: 40px;
  height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
}

.hamburger-menu {
  width: 22px;
  height: 22px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  outline: 0;
  cursor: pointer;
}
