.welcome-page-container {
  margin-bottom: 90px;
}

@media (min-width: 651px) {
  .welcome-top-boxes-container {
    display: flex;
    justify-content: start;
    margin-top: 30px;
    margin-left: 0;
    margin-right: auto;
    max-width: 960px;
    height: auto;
  }
}

@media (max-width: 650px) {
  .welcome-top-boxes-container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.welcome-left-box-container {
  max-width: 600px;
}

.welcome-right-box-container {
  width: 300px;
  min-width: 300px;
  height: 500px;
  margin-right: 0px;
}

@media (max-width: 650px) {
  .welcome-right-box-container {
    margin-left: auto;
    margin-right: auto;
  }
}

.welcome-check-container {
  margin-top: 30px;
  margin-bottom: 15px;
}

.welcome-cta-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.welcome-cta-button:hover,
.welcome-cta-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.welcome-cta-button:disabled {
  opacity: 0.6;
}

.welcome-cta-button-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: var(--UIcolour0);
  border: 1px solid var(--brandColour1);
  border-radius: var(--UIborderRadius);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--brandColour1);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.welcome-cta-button-inverted:hover,
.welcome-cta-button-inverted:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  outline: 0;
}

.welcome-cta-button-inverted:disabled {
  opacity: 0.6;
}

.welcome-cta-button-icon-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: var(--UIcolour0);
  border: 1px solid var(--brandColour1);
  border-radius: var(--UIborderRadius);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--brandColour1);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.welcome-cta-button-icon-inverted:hover,
.welcome-cta-button-icon-inverted:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  outline: 0;
}

.welcome-cta-button-icon-inverted:disabled {
  opacity: 0.6;
}

.welcome-heading {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 32px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 15px;
}

.welcome-heading-tm {
  margin-left: 0px;
  margin-top: -80px;
  padding-top: -80px;
  font-size: 9px;
  vertical-align: text-top;
}
