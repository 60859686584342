
.landing-hamburger-menu {
  width: 22px;
  height: 22px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  outline: 0;
  cursor: pointer;
}

.landing-sidebar {
  background-color: #ffffff;
  width: 30%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 400px) {
  .landing-sidebar {
    width: 75%;
  }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .sidebar {
    width: 50%;
  }
}

@media only screen and (min-width: 601px) {
  .landing-sidebar {
    width: 40%;
  }
}

.landing-sidebar-header {
  background-color: #ffffff;
  height: 62px;
  display: flex;
  margin-left: auto;
  margin-right: 2px;
  margin-bottom: 15px;
}

.landing-menu-nav {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: center;
}

@media only screen and (min-width: 601px) {
.landing-menu-nav a,
.landing-menu-nav a:visited {
  font-family: var(--UIfontType1);
  font-size: 22px;
  font-weight: 400;
  color: #333333;
  text-decoration: none;
  text-align: center;
  margin: 15px;
}
.landing-menu-nav a:hover,
.landing-menu-nav a:active {
  font-family: var(--UIfontType1);
  font-size: 22px;
  font-weight: 400;
  color: var(--UIcolour2);
  text-decoration: none;
  text-align: center;
}
}

@media only screen and (max-width: 600px) {
  .landing-menu-nav a,
.landing-menu-nav a:visited {
  font-family: var(--UIfontType1);
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  text-decoration: none;
  text-align: center;
  margin: 12px;
}
.landing-menu-nav a:hover,
.landing-menu-nav a:active {
  font-family: var(--UIfontType1);
  font-size: 20px;
  font-weight: 400;
  color: var(--UIcolour2);
  text-decoration: none;
  text-align: center;
  margin: 12px;
}
}

.landing-body-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: center;
  max-width: 960px;
}

.landing-cover-image-wrapper {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 77px;
  margin-bottom: 15px;
  text-align: center;
  max-width: 960px;
}

.landing-cover-outer-container {
  margin-top: 62px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.landing-cover-mid-container {
  margin: auto;
}

.landing-cover-inner-container {
  margin: auto;
}

.landing-cover-image-container {
  margin: auto;
  position: relative;
}

.landing-cover-image-video {
    margin-top: 15px;
    margin-bottom: auto;
    z-index: -1;
    max-height: 800px;
    max-width: 800px;
  }

.landing-cover-content-container {
  margin: auto;
}

.landing-cta-button-cover-wrapper {
  margin: auto;
  display: flex;
  justify-content: center;
}

.landing-cta-button-cover-inner-wrapper {
  margin: auto;
  display: flex;
  flex-direction: row;
}

@media only screen and (min-width: 650px) {
  .landing-cover-inner-container {
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    flex-wrap: nowrap;
    
  }
  .landing-cover-image-container {
    margin: auto;
    align-self: center;
    width: 450px;
    height: 450px;
  }
  .landing-cover-image-video {
    margin-top: 30px;
    margin-bottom: auto;
    z-index: -1;
    max-height: 800px;
    max-width: 800px;
    width: 100%;
    height: auto;
  }

  .landing-cover-content-container {
    margin-left: 15px;
    margin-top: 60px;
    max-width: 450px
  }
  .landing-cover-content-container h1 {
    text-align: left;
  }
  .landing-cover-content-container h2 {
    text-align: left;
  }
  .landing-cover-content-container .cta-button .cta-button-cover {
    margin-left: 20px;
    margin-right: auto;
  }

  .landing-cover-content-container .cta-button-wrapper {
    justify-content: start;
  }

  .landing-view-example-icon {
    margin-left: 5px !important;
  }

  .landing-cta-button-cover-wrapper {
    margin: auto;
    margin-left: 20px;
    display: flex;
    justify-content: center;
  }

  .landing-cta-button-cover-inner-wrapper {
    margin: auto;
    margin-left: 0px;
    display: flex;
    flex-direction: row;
  }

  .landing-cta-button-subtext {
    font-family: var(--UIfontType1);
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: #9b9b9b;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .landing-example-button-cover {
    right: 10px; bottom: 50px
  }
}

@media only screen and (max-width: 649px) {
.landing-cta-button-subtext {
    font-family: var(--UIfontType1);
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: #9b9b9b;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .landing-example-button-cover {
  right: 15px;
  bottom: 15px
  }
}

/* Start Avatar banner styles */

.landing-avatar-banner-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
}

.landing-avatar-banner {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  background-color: #e5f3f7;
  align-items: top;
  justify-content: center;
  border: none;
  border-radius: 4px;
}

.landing-avatar-banner-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.landing-avatar-image-and-text-outer-wrapper {
  max-width: 360px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding: 15px;
}

.landing-avatar-image-and-text-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.landing-avatar-image-wrapper {
  margin: auto;
  display: flex;
  justify-content: flex-end;
}

img.landing-avatar-image {
  border: none;
  border-radius: 50%;
  height: 90px;
  width: 90px;
}

.landing-avatar-banner-text-wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.landing-avatar-banner-text {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #6a6a6a;
  font-size: 18px;
  line-height: 130%;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.landing-avatar-banner-cta-button-wrapper {
  margin: auto;
}

.landing-avatar-banner-cta-button {
  background-color: #1ec1a1;
  width: 260px;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: var(--UIfontType1);
  font-size: 18px;
  color: white;
  text-align: center;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 30px;
  cursor: pointer;
}

.landing-avatar-banner-cta-button:hover,
.landing-avatar-banner-cta-button:focus {
  outline: 0;
  background-color: #22ba9d;
}

.landing-avatar-banner-content-wrapper {
  margin-bottom: 20px;
}

@media only screen and (min-width: 650px) {
  .landing-avatar-banner-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 15px;
    }
}

.landing-avatar-banner-content-inner-wrapper {
  margin: 0
}

img.landing-qr-code-image {
  border: none;
  border-radius: 4px;
  height: 90px;
  width: 90px;
}

/* End Avatar banner styles */

.landing-step-banner-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 60px;
  margin-bottom: 30px;
}

.landing-box-banners-wrapper {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
  background: white;
}

.landing-box-banner-wrapper {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  border: none;
}

@media only screen and (min-width: 650px) {
  .landing-box-banners-wrapper {
    flex-wrap: nowrap;
  }
  .landing-box-banner-wrapper {
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}

.landing-box-banners-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 45px;
  margin-bottom: 45px;
}

.landing-box-image-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0px;
  margin-bottom: 30px;
  text-align: center;
  max-width: 960px;
}

.landing-box-icon-image-wrapper {
  margin: auto;
  height: 60px;
  width: 60px;
}

.landing-badge-banner-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 45px;
  margin-bottom: 30px;
}

.landing-box-badge-image-wrapper {
  margin: auto;
  height: auto;
  width: 250px;
  }

.landing-number-bullet-wrapper {
  margin: auto;
  display: flex;
  justify-content: flex-end;
}

.landing-number-bullet {
  height: 40px;
  width: 40px;
  background: #1ec1a1;
  border-radius: 50%;
  text-align: center;
  display: flex;
  margin: auto;
}

.landing-number-bullet-text {
  margin: auto;
  font-family: var(--UIfontType1);
  font-weight: 400;
  font-size: 20px;
  color: white;
}

.landing-box-text-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  max-width: 960px;
}

.landing-box-text-wrapper p {
  color: #6a6a6a;
}

.landing-cta-button-wrapper {
  margin: auto;
  display: flex;
  justify-content: center;
}

.landing-cta-button-cover {
  -webkit-appearance: none;
  background-color: #1ec1a1;
  width: auto;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: var(--UIfontType1);
  font-size: 18px;
  color: white;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.landing-cta-button-cover:hover,
.landing-cta-button-cover:focus {
  background-color: #22ba9d;
  border: none;
  outline: 0;
}

.landing-cta-button {
  -webkit-appearance: none;
  background-color: #1ec1a1;
  width: auto;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: var(--UIfontType1);
  font-size: 18px;
  color: white;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.landing-cta-button:hover,
.landing-cta-button:focus {
  background-color: #22ba9d;
  border: none;
  outline: 0;
}

.landing-example-button-cover {
  -webkit-appearance: none;
  background-color: #ffffff;
  width: 80px;
  height: 80px;
  border: 1px solid #1ec1a1;
  border-radius: 100%;
  font-family: var(--UIfontType1);
  font-size: 14px;
  color: #1ec1a1;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  transition: color 0.15s box-shadow 0.15s;
  z-index: 99;
  position: absolute; 
  
}

.landing-example-button-cover:hover,
.landing-example-button-cover:focus {
  color: #22ba9d;
  border: 1px solid #22ba9d;
  outline: 0;
  box-shadow: 0px 3px 5px var(--UIcolour6);
}

.landing-header-button-and-menu-wrapper {
  margin-right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.landing-header-cta-button {
  -webkit-appearance: none;
  background-color: #1ec1a1;
  width: auto;
  height: 35px;
  border: none;
  border-radius: 4px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
  color: white;
  text-align: center;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: auto;
  padding-bottom: auto;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.landing-header-cta-button:hover,
.landing-header-cta-button:focus {
  background-color: #22ba9d;
  border: none;
  outline: 0;
}

@media only screen and (max-width: 469px) {
  .landing-header-cta-button {
    padding-left: 10px !important;
  padding-right: 10px !important;
  }
}

@media only screen and (min-width: 470px) {
  .landing-header-cta-button-inverted {
  -webkit-appearance: none;
  background-color: white;
  width: 100px;
  height: 35px;
  border: 1px solid #1ec1a1;
  border-radius: 4px;
  font-family: var(--UIfontType1);
  font-size: 18px;
  color: #1ec1a1;
  text-align: center;
  padding: auto;
  margin-left: 0px;
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.15s;
  transition: background-color 0.15s;
  transition: border 0.15s;
}

.landing-header-cta-button-inverted:hover,
.landing-header-cta-button-inverted:focus {
  background-color: #22ba9d;
  color: white;
  border: 1px solid #22ba9d;
  outline: 0;
}
}

@media only screen and (max-width: 469px) {
  .landing-header-cta-button-inverted {
    display: none;
  }
}

.landing-menu-cta-button {
  -webkit-appearance: none;
  background-color: #1ec1a1;
  width: 180px;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: var(--UIfontType1);
  font-size: 18px;
  color: white;
  text-align: center;
  padding: auto;
  margin-left: 0px;
  margin-right: 0px;
  cursor: pointer;
  transition: background-color 0.15s;
}

.landing-menu-cta-button:hover,
.landing-menu-cta-button:focus {
  background-color: #22ba9d;
  border: none;
  outline: 0;
}

.landing-menu-cta-button-inverted {
  -webkit-appearance: none;
  background-color: white;
  width: 180px;
  height: 50px;
  border: 1px solid #1ec1a1;
  border-radius: 4px;
  font-family: var(--UIfontType1);
  font-size: 18px;
  color: #1ec1a1;
  text-align: center;
  padding: auto;
  margin-left: 0px;
  margin-right: 0px;
  cursor: pointer;
  transition: color 0.15s;
  transition: background-color 0.15s;
  transition: border 0.15s;
}

.landing-menu-cta-button-inverted:hover,
.landing-menu-cta-button-inverted:focus {
  background-color: #22ba9d;
  color: white;
  border: 1px solid #22ba9d;
  outline: 0;
}

.landing-h1 {
  font-family: var(--UIfontType1SemiBold);
  font-size: 28px;
  color: #333333;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
}

@media (max-width: 649px) {
.landing-h1 {
  font-size: 28px;
}
}
@media (min-width: 650px) {
.landing-h1 {
  font-size: 48px;
}
}

.landing-h2 {
  font-family: var(--UIfontType1);
  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  color: #6a6a6a;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing-h3 {
  font-family: var(--UIfontType1SemiBold);
  font-weight: 600;
  color: #333333;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing-h4 {
  font-family: var(--UIfontType1);
  font-weight: 300;
  color: #6a6a6a;
  font-size: 15px;
  line-height: 150%;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
}

.landing-h5 {
  font-family: var(--UIfontType1SemiBold);
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing-p {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #6a6a6a;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

.landing-ul {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #333333;
  font-size: 18px;
  line-height: 150%;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
}

.landing-ol {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #333333;
  font-size: 18px;
  line-height: 150%;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
}

.landing-section-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: center;
  border: none;
}

.landing-section-break-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
}

@media only screen and (min-width: 650px) {
  .landing-section-break-wrapper {
    margin-top: 45px;
  }
}

.landing-section-break {
  border-top: 1px solid #cccccc;
  height: auto;
  max-width: 960px;
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing-footer {
  width: auto;
  max-width: 960px;
  background-color: #ffffff;
  height: auto;
  border-top: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
}

.landing-footer p {
  font-family: var(--UIfontType1);
  color: #6a6a6a;
  font-size: 13px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.landing-footer p a,
.landing-footer p a:visited,
.landing-footer p a:hover,
.landing-footer p a:active {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  text-align: center;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.landing-play-button {
  -webkit-appearance: none;
  background-color: transparent;
  width: 160px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  border: none;
  border-radius: 4px;
  font-family: var(--UIfontType1);
  font-size: 18px;
  color: #1ec1a1;
  text-align: center;
  padding: 5px;
  margin-left: 5px;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.15s;
  outline: 0;
}

.landing-play-button:hover,
.landing-play-button:focus {
  color: #22ba9d;
  border: none;
  outline: 0;
}

.landing-play-button-text {
  margin: auto;
  margin-right: 0px;
}

.landing-play-button-icon-container {
  width: 18px;
  height: 18px;
  margin: auto;
  margin-left: 10px;
}

@media only screen and (max-width: 400px) {
  .landing-video-container {
    width: auto;
    height: auto;
    margin: 0;
  }

  .landing-close-button {
    background-color: white;
    border-radius: 50%;
    border: none;
    height: 30px;
    width: 30px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
  }

  .landing-close-button-icon {
  background-color: transparent;
  height: 12px;
  width: 12px;
  margin: auto;
}

}

@media only screen and (min-width: 401px) and (max-width: 959px) {
  .landing-video-container {
    width: auto;
    height: auto;
    margin: calc(100vw * 0.02);
  }

  .landing-close-button {
    background-color: white;
    border-radius: 50%;
    border: none;
    height: 50px;
    width: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    z-index: 999;
    position: fixed;
    top: calc(100vw * 0.02);
    right: calc(100vw * 0.02);
  }

  .landing-close-button-icon {
  background-color: transparent;
  height: 16px;
  width: 16px;
  margin: auto;
}

}

@media only screen and (min-width: 960px) {
  .landing-video-container {
    width: auto;
    height: 720px;
    margin-top: 20px;
    margin-left: calc(100vw * 0.1);
    margin-right: calc(100vw * 0.1);
  }

  .landing-close-button {
    background-color: white;
    border-radius: 50%;
    border: none;
    height: 50px;
    width: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    z-index: 999;
    position: fixed;
    top: 20px;
    right: calc(100vw * 0.1);
  }

  .landing-close-button-icon {
  background-color: transparent;
  height: 16px;
  width: 16px;
  margin: auto;
}
}

.landing-close-button:focus {
  outline: 0;
}

.landing-close-button-icon-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}

.landing-questions-outer-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
}

.landing-questions-wrapper {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 960px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
}

.landing-question {
  margin-bottom: 60px;
}

.landing-question p {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #333333;
  font-size: 18px;
  line-height: 150%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
}

.landing-ap-o-accordion {
  grid-column: 2/24;
  width: 100%;
}
.landing-ap-o-accordion section {
  margin-bottom: 30px;
  border-radius: 4px;
}

.landing-ap-o-accordion section:focus {
  outline: 0;
}

.landing-ap-o-accordion section:last-child {
  margin-bottom: 0;
}
.landing-ap-o-accordion section[expanded] {
  background-color: #fff;
}
.landing-ap-o-accordion section[expanded] .ap-o-accordion-header {
  border-color: transparent;
}
.landing-ap-o-accordion section[expanded] .ap-a-ico {
  transform: scale(-1);
}
.landing-ap-o-accordion-header {
  padding: 15px;
  margin-bottom: 0;
  background: 0 0;
  border-radius: 4px;
  border: 1px solid #cccccc94;
  transition: border 0.3s ease;
}
.landing-ap-o-accordion-header:hover {
  border: 1px solid #1ec1a1;
  background-color: #fff;
}

.landing-ap-o-accordion-header:focus {
  outline: 0;
}

.landing-ap-o-accordion-title {
  display: flex;
  align-items: center;
}

.landing-ap-o-accordion-title .ap-a-ico {
  width: 1em;
  height: 1em;
  margin-left: auto;
}
@media (min-width: 768px) {
  .landing-ap-o-accordion-title .ap-a-ico {
    width: 1.375em;
    height: 1.375em;
  }
}
.landing-ap-o-accordion-content {
  padding: 0 30px 0px;
  background: #f0f0f094
}
.landing-ap-o-accordion-list {
  list-style: none;
  column-count: 2;
  padding-left: 0;
}
@media (min-width: 768px) {
  .landing-ap-o-accordion-list {
    column-count: 3;
  }
}
.landing-ap-o-accordion-list-item {
  margin-bottom: 10px;
  line-height: 1.6rem;
  font-weight: 400;
  color: #cccccc94;
  font-size: 0.875rem;
}



/* Star ratings */

.landing-avatar-image-wrapper-centre {
  margin: auto;
  display: flex;
  justify-content: center;
}

.landing-star-icons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center
}

.landing-star-icon-image-wrapper {
  margin: 2px;
  height: 25px;
  width: 25px;
}

.landing-review-link {
  font-family: var(--UIfontType1SemiBold);
  font-weight: 600;
  color: #333333;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-left: 5px;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 0px;
}

.landing-review-link a:link {
  color: #333333;
  text-decoration: underline;
  text-decoration-color: #1ec1a1;
}

.landing-review-link a:hover {
  color: #6a6a6a;
  text-decoration: underline;
  text-decoration-color: #6a6a6a;
}

.landing-review-link a:active {
  color: #6a6a6a;
  text-decoration: underline;
  text-decoration-color: #6a6a6a;
}

.landing-review-link a:visited {
  color: #333333;
  text-decoration: underline;
  text-decoration-color: #333333;
}

.landing-review-link-no-link {
  font-family: var(--UIfontType1SemiBold);
  font-weight: 600;
  color: #333333;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-left: 5px;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 0px;
}

/* Start mini banner styles */

.landing-mini-banner-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
  background: white;
}

@media only screen and (max-width: 649px) {
.landing-mini-banner-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  }
}

@media only screen and (min-width: 650px) {
.landing-mini-banner-wrapper {
  margin-top: 60px;
  margin-bottom: 45px;
  }
}


.landing-mini-banner {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #e5f3f7;
  border-radius: 4px
}

.landing-mini-banner-text-wrapper {
  margin: 0px;
  padding: 5px;
  height: 100%;
  text-align: center;
  max-width: 960px;
  display: flex;
  flex-direction: row;
  justify-content: center
}

@media only screen and (max-width: 649px) {
.landing-mini-banner-text-wrapper {
    flex-wrap: wrap
  }
}

@media only screen and (min-width: 650px) {
.landing-mini-banner-text-wrapper {
    flex-wrap: nowrap
  }
}

.landing-mini-banner-heading {
  font-family: var(--UIfontType1SemiBold);
  font-weight: 600;
  color: #333333;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 649px) {
.landing-mini-banner-heading {
  margin-bottom: 15px;
  }
}

@media only screen and (min-width: 650px) {
.landing-mini-banner-heading {
  margin-bottom: 15px;
  }
}

.landing-mini-banner-text-link {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px; 
}

@media only screen and (max-width: 649px) {
.landing-mini-banner-text-link {
  margin-top: 0px;
  margin-bottom: 15px;
  }
}

@media only screen and (min-width: 650px) {
.landing-mini-banner-text-link {
  margin-top: 15px;
  margin-bottom: 15px;
  }
}

.landing-mini-banner-text-link a:link {
  color: #333333;
  text-decoration: underline;
  text-decoration-color: #6a6a6a;
}

.landing-mini-banner-text-link a:hover {
  color: #6a6a6a;
  text-decoration: underline;
  text-decoration-color: #6a6a6a;
}

.landing-mini-banner-text-link a:active {
  color: #6a6a6a;
  text-decoration: underline;
  text-decoration-color: #6a6a6a;
}

.landing-mini-banner-text-link a:visited {
  color: #333333;
  text-decoration: underline;
  text-decoration-color: #6a6a6a;
}

/* End mini banner styles */

/* Price section specific styles */

.landing-section-heading {
  font-family: var(--UIfontType1SemiBold);
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  margin-bottom: 15px;
}

.landing-price-banners-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
}

.landing-price-banner-wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
  padding-left: 10px;
  padding-right: 10px;

}

@media only screen and (min-width: 780px) {
  .landing-price-banners-wrapper {
    flex-wrap: nowrap;
  }
  .landing-price-banner-wrapper {
    max-width: 280px;
  }

}

@media only screen and (max-width: 649px) {
.landing-price-banner {
  height: 100%;
}
}

.landing-price-banner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  background-color: #e5f3f7;
  border-radius: 4px
}

.landing-price-banner-inner-wrapper {
  margin: auto
}

.landing-price-banner-text-wrapper {
  margin: 20px;
  padding: 20px;
  height: 100%;
  text-align: center;
  max-width: 960px;
}

.landing-price-banner-price-wrapper {
  margin: auto;
  display: flex;
}

.landing-price-banner-price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
}

.landing-price-banner-price-text-large {
  font-family: var(--UIfontType1SemiBold);
  font-size: 42px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 100%;
  text-align: top;
  margin: auto;
  margin-top: 3px;
}

.landing-price-banner-price-text-small-left {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #333333;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  margin-left: auto;
  margin-right: 5px;
  margin-top: 5px;
}

.landing-price-banner-price-text-small-right {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #333333;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  margin-left: 5px;
  margin-right: auto;
  margin-top: 5px;
}

.landing-price-banner-text-small {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #6a6a6a;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-left: 5px;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 15px;
}

.landing-price-banner-text-smallest {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #6a6a6a;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  margin-left: 5px;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 15px;
}

.landing-price-banner-text-special {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #ed495a;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-left: 5px;
  margin-right: auto;
  margin-top: 15px;
}

.landing-price-text-link {
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #333333;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.landing-price-text-link a:link {
  color: #1ec1a1;
  text-decoration: underline;
  text-decoration-color: #1ec1a1;
}

.landing-price-text-link a:hover {
  color: #6a6a6a;
  text-decoration: underline;
  text-decoration-color: #6a6a6a;
}

.landing-price-text-link a:active {
  color: #6a6a6a;
  text-decoration: underline;
  text-decoration-color: #6a6a6a;
}

.landing-price-text-link a:visited {
  color: #1ec1a1;
  text-decoration: underline;
  text-decoration-color: #1ec1a1;
}

.landing-price-banner-section-break-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
}

.landing-price-banner-section-break {
  border-top: 1px solid #cccccc;
  height: auto;
  max-width: 960px;
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.landing-price-banner-text-list-outer-wrapper {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.landing-price-banner-text-list-wrapper {
  margin: auto;
  text-align: center;
  max-width: 960px;
}

.landing-price-banner-text-list-wrapper ul {
  margin: auto;
  margin-left: 0px;
  padding-bottom: 30px;
}

.landing-price-banner-text-list-wrapper li {
  list-style-type: circle;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 15px;
  font-family: var(--UIfontType1);
  font-weight: 400;
  color: #6a6a6a;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
}


.landing-cta-button-price {
  -webkit-appearance: none;
  background-color: #1ec1a1;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: var(--UIfontType1);
  font-size: 18px;
  color: white;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: background-color 0.15s;
}

.landing-cta-button-price:hover,
.landing-cta-button-price:focus {
  background-color: #22ba9d;
  border: none;
  outline: 0;
}

.landing-cta-button-price-subtext {
    font-family: var(--UIfontType1);
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: #9b9b9b;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    margin-bottom: 15px;
  }

.landing-select-wrapper {
  margin: auto
}

.landing-select {
  /* styling */
  background-color: white;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  display: inline-block;
  font-style: var(--UIfontType1);
  color: var(--UIcolour2);
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  
  /* reset */
  margin: 0;     
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  }
  
  .landing-select.minimal {
    background-image:
    linear-gradient(45deg, transparent 50%, var(--UIcolour2)),
    linear-gradient(135deg, var(--UIcolour2), transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
    background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    }
    
  .landing-select.minimal:focus {
    background-image:
    linear-gradient(45deg, var(--brandColour1), transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--brandColour1)),
    linear-gradient(to right, #ccc, #ccc);
    background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: var(--brandColour1);
    outline: 0;
    }
    
  .landing-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
    }


    .landing-light-box-banner {
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 15px;
    background: 0 0;
    border-radius: 4px;
    border: 1px solid #cccccc94;
  }


.landing-list-box {
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 4px;
  border: 1px solid #cccccc94;
  max-width: 960px;
  margin-bottom: 15px;
  }
  
  @media only screen and (min-width: 650px) {
    .landing-list-box-list-wrapper {
    max-width: 600px;
    margin: auto;
    margin-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
  }

  @media only screen and (max-width: 649px) and (min-width: 376px) {
    .landing-list-box-list-wrapper {
    max-width: 400px;
    min-width: 200px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 20px;
  }
}

@media only screen and (max-width: 375px) {
    .landing-list-box-list-wrapper {
    max-width: 250px;
    min-width: 200px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 20px;
  }
}

  .landing-list-box-list-item {
    margin-left: auto;
    margin-bottom: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center
    }

    .landing-list-box-list-icon-wrapper {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: auto;
    height: 22px;
    width: 22px;
    }
    
  .landing-list-box-list-item-text {
    font-family: var(--UIfontType1);
    font-weight: 400;
    color: #333333;
  font-size: 17px;
  line-height: 120%;
  text-align: left;
  margin-left: 8px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: auto
    }
