.show {
  margin: auto;
}

.hide {
  display: none;
}

.infoitem-container {
  margin: auto;
  margin-left: 0;
  max-width: 555px;
}

.infoitem-heading-and-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infoitem-heading {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
}

.infoitem-edit-button {
  -webkit-appearance: none;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  transition: color underline var(--UItransitionTime);
}

.infoitem-edit-button:hover {
  text-decoration: underline;
}

.infoitem-edit-button:focus {
  outline: 0;
}

.infoitem-inline-edit-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}

.infoitem-inline-edit-button:hover {
  text-decoration: underline;
  opacity: 0.75;
}

.infoitem-inline-edit-button:focus {
  outline: 0;
  opacity: 0.75;
}

.infoitem-value {
  margin-left: 0;
  margin-right: 5px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour1);
}

.infoitem-attachments-outer-container {
  margin-top: 8px;
  margin-bottom: 12px;
}

.infoitem-attachments-container {
  margin: auto;
  margin-left: 0;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.infoitem-attachments-banner-container {
  margin-top: 0;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 0px;
  display: flex;
}

.infoitem-preview-container {
  margin-left: 0px;
  margin-top: 0;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.infoitem-preview-container-inner {
  margin-left: 0;
  margin-top: 8px;
  border-radius: var(--UIborderRadius);
  width: 150px;
  height: 150px;
  object-fit: contain;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoitem-preview-video {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  background: black;
  object-fit: contain;
  border: none;
  border-radius: var(--UIborderRadius);
  height: calc(var(--width) / 1.5);
  width: auto !important;
  height: auto !important;
  max-width: 150px;
  max-height: 150px;
}

.infoitem-image-container {
  margin-left: 0;
  margin-top: 8px;
  margin-bottom: 15px;
  display: flex;
  justify-content: start;
}

.infoitem-image {
  margin-left: 0;
  margin-top: 0;
  border-radius: var(--UIborderRadius);
  width: auto;
  height: 150px;
  object-fit: contain;
}

.infoitem-image-preview-container {
  margin-left: 0;
  margin-top: 8px;
  border-radius: var(--UIborderRadius);
  width: 150px;
  height: 150px;
  object-fit: contain;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoitem-image-preview {
  margin: auto;
  margin-top: 0;
  margin-bottom: 0;
  background: black;
  object-fit: contain;
  border: none;
  border-radius: var(--UIborderRadius);
  height: calc(var(--width) / 1.5);
  width: auto !important;
  height: auto !important;
  max-width: 150px;
  max-height: 150px;
}

.infoitem-image-input {
  display: none;
}

.custom-file-upload {
  color: var(--UIcolour2);
  text-align: center;
  margin-top: 8px;
  padding: auto;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  border: 1px;
  border-style: dashed;
  border-color: var(--UIcolour3);
  height: 150px;
  width: 150px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.custom-file-upload:hover {
  background: var(--UIcolour7);
}

.custom-file-upload:hover .custom-file-upload-icon {
  fill: var(--brandColour1Dulled);
}

.custom-file-upload:hover .custom-file-upload-text {
  color: var(--brandColour1Dulled);
}

.custom-file-upload:focus {
  background: var(--UIcolour7);
}

.custom-file-upload:focus .custom-file-upload-icon {
  fill: var(--brandColour1Dulled);
}

.custom-file-upload:focus .custom-file-upload-text {
  color: var(--brandColour1Dulled);
}

.custom-file-upload-inner-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-file-upload-icon {
  margin: auto;
  height: 30px;
  width: 30px;
  fill: var(--brandColour1);
}
.custom-file-upload-text {
  margin-top: 10px;
  margin-bottom: auto;
  color: var(--brandColour1);
}

.infoitem-file-delete-button {
  z-index: 5;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: none;
  background: white;
  cursor: pointer;
}
.infoitem-file-delete-icon {
  margin: auto;
  height: 20px;
  width: 20px;
  fill: var(--UIcolour2);
}

.infoitem-file-delete-button:hover .infoitem-file-delete-icon {
  fill: var(--brandColour1Dulled);
}

.infoitem-file-delete-button:focus {
  background: var(--UIcolour7);
  outline: 0;
}

.infoitem-file-delete-button:focus .infoitem-file-delete-icon {
  fill: var(--brandColour1Dulled);
}

.infoitem-form-container {
  margin-left: 0;
}

.infoitem-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 15px;
}

.infoitem-form-inner-container-left {
  margin: auto;
  margin-right: 15px;
  margin-left: 0;
  margin-bottom: 0;
  width: 270px;
  display: flex;
  flex-direction: column;
}

.infoitem-form-inner-container-map {
  margin: auto;
  margin-right: auto;
  margin-left: 0;
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
}

.infoitem-form-inner-container-full {
  margin: auto;
  margin-right: 15px;
  margin-left: 0;
  width: 450px;
  display: flex;
  flex-direction: column;
}

.infoitem-form-inner-container-textarea {
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.infoitem-list-container {
  margin: auto;
  margin-right: 0px;
  margin-left: 0;
  margin-bottom: 15px;
  width: 100%;
  max-width: 555px;
  display: flex;
  flex-direction: column;
}

.infoitem-listitem {
  margin: auto;
  margin-right: 15px;
  margin-left: 0;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  max-width: 555px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.infoitem-listitem-image-and-detail-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
}

.infoitem-listitem-detail-container {
  margin-top: 0;
  margin-right: 5px;
  width: 100%;
}

.infoitem-listitem-heading {
  margin-left: 0;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 8px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 16px;
}

.infoitem-listitem-text {
  font-size: 15px;
  color: var(--UIcolour2);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.infoitem-listitem-edit-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  opacity: 1;
  transition: color opacity var(--UItransitionTime);
  text-decoration: underline;
  text-align: left;
}

.infoitem-listitem-edit-button:hover {
  opacity: 0.75;
}

.infoitem-listitem-edit-button:focus {
  opacity: 0.75;
  outline: 0;
}

.infoitem-listitem-textarea-and-button-container {
  display: flex;
  flex-direction: column;
  width: auto;
}

.infoitem-listitem-textarea {
  width: auto;
  height: 120px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 10px;
  font-size: 15px;
  color: var(--UIcolour2);
  line-height: 125%;
  transition: border-color var(--UItransitionTime);
}

.infoitem-listitem-textarea:focus {
  border-color: var(--brandColour1);
  outline: 0;
}

.infoitem-listitem-textarea::placeholder {
  color: var(--UIcolour3);
}

.infoitem-listitem-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 0px;
  width: 80px;
  height: auto;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: var(--UIcolour0);
  font-size: 13px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.infoitem-listitem-button:hover,
.infoitem-listitem-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.infoitem-listitem-button-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 0px;
  width: auto;
  height: auto;
  background-color: var(--UIcolour0);
  border: 1px solid;
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: var(--brandColour1);
  font-size: 13px;
  cursor: pointer;
  transition: border var(--UItransitionTime);
  transition: color var(--UItransitionTime);
  transition: background-color var(--UItransitionTime);
}

.infoitem-listitem-button-inverted:hover,
.infoitem-listitem-button-inverted:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  border: none;
  outline: 0;
}

.infoitem-listitem-button-danger {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 60px;
  height: 30px;
  background-color: var(--UIcolour5);
  border: none;
  border-radius: var(--UIborderRadius);
  color: var(--UIcolour0);
  font-size: 13px;
  cursor: pointer;
  opacity: 1;
  transition: opacity var(--UItransitionTime);
}

.infoitem-listitem-button-danger:hover,
.infoitem-listitem-button-danger:focus {
  opacity: 0.75;
  outline: 0;
}

.infoitem-card-section-container {
  margin: auto;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}

.infoitem-card-section-container-inner {
  width: auto;
  margin-left: 0px;
  margin-right: 25px;
  margin-top: 8px;
  margin-bottom: 10px;
}

.infoitem-form-inner-container-right {
  margin: auto;
  margin-right: 0px;
  margin-left: 0;
  width: 270px;
  display: flex;
  flex-direction: column;
}

.infoitem-form-label {
  font-size: 16px;
  color: var(--UIcolour1);
}

.infoitem-form-label-map {
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--UIcolour1);
}

.infoitem-text-preview {
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 125%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.infoitem-text-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding-left: 10px;
  height: 40px;
  font-size: 16px;
  color: var(--UIcolour2);
  transition: border-color var(--UItransitionTime);
}

.infoitem-text-input:focus {
  border-color: var(--brandColour1);
}

.infoitem-text-input::placeholder {
  color: var(--UIcolour3);
}

.infoitem-textarea {
  width: auto;
  height: 120px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 8px;
  margin-bottom: 12px;
  border: solid 1px;
  border-color: var(--UIcolour3);
  border-radius: var(--UIborderRadius);
  padding: 10px;
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 125%;
  transition: border-color var(--UItransitionTime);
}

.infoitem-textarea:focus {
  border-color: var(--brandColour1);
  outline: 0;
}

.infoitem-textarea::placeholder {
  color: var(--UIcolour3);
}

.infoitem-save-button {
  font-family: "SFProText-Semibold", Sans-Serif;
  -webkit-appearance: none;
  letter-spacing: 0.02em;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 160px;
  height: 50px;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding: 5px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.infoitem-save-button:hover,
.infoitem-save-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.infoitem-save-button:disabled {
  opacity: 0.6;
}

.infoitem-save-button-container-padding-left {
  padding-left: 28px;
}

.infoitem-delete-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 0px;
  width: 110px;
  height: 30px;
  background-color: var(--UIcolour0);
  border: solid 1px var(--UIcolour5);
  border-radius: var(--UIborderRadius);
  padding: auto;
  color: var(--UIcolour5);
  font-size: 15px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
  transition: color var(--UItransitionTime);
}

.infoitem-delete-button:hover,
.infoitem-delete-button:focus {
  background-color: var(--UIcolour5);
  color: var(--UIcolour0);
  outline: 0;
}

.infoitem-delete-button:disabled {
  opacity: 0.6;
}

.infoitem-delete-button-container-padding-left {
  padding-left: 28px;
}

.infoitem-form-error-message {
  margin-top: 10px;
  font-size: 14px;
  color: var(--UIcolour5);
}

.infoitem-section-break-wrapper {
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  justify-content: center;
  border: none;
}

.infoitem-section-break {
  border-top: 1px solid #cccccc;
  height: auto;
  max-width: 960px;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Google Autocomplete search input */

.pac-container {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: var(--UIborderRadius);
  border-top: 1px solid var(--UIcolour3);
  border-left: 1px solid var(--UIcolour3);
  border-right: 1px solid var(--UIcolour3);
  border-bottom: 1px solid var(--UIcolour3);
  font-family: "SF Pro Text", Sans-Serif;
  box-shadow: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
  padding: 0;
  margin: 0;
}

.pac-icon {
  display: none;
}

.pac-item-query {
  font-family: "SF Pro Text", Sans-Serif;
  padding-left: 7px;
  font-size: 16px;
  color: var(--UIcolour1);
}

.pac-item {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 16px;
  color: var(--UIcolour2);
  line-height: 250%;
  margin-top: auto;
  margin-bottom: auto;
}

.pac-item:hover {
  background: var(--brandColour2);
}

/* Mobile */

@media only screen and (max-width: 600px) {
  .infoitem-form-inner-container-left {
    width: 100%;
    margin: 0;
  }
  .infoitem-form-inner-container-right {
    width: 100%;
    margin: 0;
  }
  .infoitem-form-inner-container-full {
    width: 100%;
    margin: 0;
  }
}
