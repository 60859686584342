.setupflow-heading-container {
  max-width: 500px;
}

.setupflow-heading {
  font-family: "SFProText-Semibold", Sans-Serif;
  font-size: 32px;
  font-weight: 600;
  color: #333333;
  letter-spacing: 0em;
  line-height: 130%;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.setupflow-subheading {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  color: #6a6a6a;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.cta-button-subtext {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  color: #6a6a6a;
  text-align: left;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.setupflow-inline-edit-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0;
  width: auto;
  height: auto;
  border: none;
  border-radius: 4px;
  color: var(--brandColour1);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  transition: color opacity var(--UItransitionTime);
  opacity: 1;
}

.setupflow-inline-edit-button:hover {
  text-decoration: underline;
  opacity: 0.75;
}

.setupflow-inline-edit-button:focus {
  outline: 0;
  opacity: 0.75;
}

.image-banners-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.image-banner-container-1 {
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-2 {
  margin: auto;
  margin-left: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-3 {
  margin: auto;
  margin-left: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-4 {
  margin: auto;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-5 {
  margin: auto;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-6 {
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.imageuploader-form-container {
  margin-left: 0;
}

.imageuploader-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 15px;
}

.imageuploader-form-inner-container-left {
  margin: 0;
  margin-right: 0px;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
}

.imageuploader-form-inner-container-left-selected {
  margin: 0;
  margin-right: 0px;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
}

.imageuploader-form-label {
  font-size: 16px;
  color: var(--UIcolour1);
}

.imageuploader-image-input {
  display: none;
}

.imageuploader-image {
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  width: 100%;
  height: 160px;
  margin-bottom: 0px;
  object-fit: cover;
}

.imageuploader-custom-file-upload {
  color: var(--UIcolour2);
  text-align: center;
  margin-top: 0px;
  margin-right: 0px;
  padding: auto;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  border: 1px;
  border-style: dashed;
  border-color: var(--UIcolour3);
  width: 100%;
  height: 200px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.imageuploader-custom-file-upload-selected {
  color: var(--UIcolour2);
  text-align: center;
  margin-top: 0px;
  margin-right: 0px;
  padding: auto;
  background-color: var(--UIcolour6);
  border-radius: var(--UIborderRadius);
  border-color: var(--UIcolour3);
  width: 100%;
  height: 160px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.imageuploader-custom-file-upload:hover {
  background: var(--UIcolour7);
  border-color: var(--brandColour1);
}

.imageuploader-custom-file-upload:hover .custom-file-upload-icon {
  fill: var(--brandColour1Dulled);
}

.imageuploader-custom-file-upload:hover .custom-file-upload-text {
  color: var(--brandColour1Dulled);
}

.imageuploader-custom-file-upload:focus {
  background: var(--UIcolour7);
}

.imageuploader-custom-file-upload:focus .custom-file-upload-icon {
  fill: var(--brandColour1Dulled);
}

.imageuploader-custom-file-upload:focus .custom-file-upload-text {
  color: var(--brandColour1Dulled);
}

.imageuploader-custom-file-upload-inner-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imageuploader-custom-file-upload-icon {
  margin: auto;
  height: 30px;
  width: 30px;
  fill: var(--brandColour1);
}
.imageuploader-custom-file-upload-text {
  margin-top: 10px;
  margin-bottom: auto;
  color: var(--brandColour1);
}

.cta-button-subtext {
  font-family: "SF Pro Text", Sans-Serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 130%;
  color: #6a6a6a;
  text-align: left;
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
}
