.image-banner {
  height: auto;
  width: 294px;
  border: solid 1px var(--UIcolour0);
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour6);
  cursor: pointer;
  transition: background var(--UItransitionTime);
  transition: border var(--UItransitionTime);
}

.image-banner:hover,
.image-banner:active {
  background: var(--UIcolour7);
  border: solid 1px var(--brandColour1);
}

.image-banner-selected {
  height: auto;
  width: 294px;
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour6);
  cursor: pointer;
  transition: background var(--UItransitionTime);
  border: solid 1px var(--brandColour1);
}

.image-banner-empty {
  height: 200px;
  width: 294px;
  border: solid 1px var(--UIcolour0);
  border-radius: var(--UIborderRadius);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: left;
  background: var(--UIcolour6);
  cursor: pointer;
  transition: background var(--UItransitionTime);
  transition: border var(--UItransitionTime);
}

.image-banner-main-image {
  border-radius: var(--UIborderRadius) var(--UIborderRadius) 1px 1px;
  width: 100%;
  height: 160px;
  margin-bottom: 0px;
  object-fit: cover;
}

.image-banner-subheading {
  font-family: var(--UIfontType1);
  font-size: 15px;
  color: var(--UIcolour2);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: auto;
}

.image-banners-container {
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.image-banner-container-1 {
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-2 {
  margin: auto;
  margin-left: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-3 {
  margin: auto;
  margin-left: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-4 {
  margin: auto;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-5 {
  margin: auto;
  margin-left: 0px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-container-6 {
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  display: flex;
}

.image-banner-label {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 3px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 10px;
  color: white;
  background: var(--brandColour1);
}

.image-banner-main-icon-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: auto;
  margin-bottom: 5px;
}

.image-banner-main-icon {
  height: 22px;
  width: 22px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--UIcolour1);
}

.image-banner-heading-and-chevron-wrapper {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image-banner-heading {
  font-family: var(--UIfontType1);
  font-size: 17px;
}

.image-banner-chevron-icon-wrapper {
  display: flex;
  justify-content: center;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px;
}

.image-banner-chevron-icon {
  height: 13px;
  width: 13px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: auto;
  margin-bottom: auto;
  fill: var(--UIcolour1);
}

.image-banner-subheading {
  font-family: var(--UIfontType1);
  font-size: 15px;
  color: var(--UIcolour2);
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: auto;
}

@media only screen and (max-width: 1005px) and (min-width: 661px) {
  .image-banner-section-container {
    width: 615px;
    margin: auto;
  }
  .image-banners-container {
    width: 615px;
  }
  .image-banner-container-3 {
    margin-left: 0px;
    margin-right: 0;
  }
  .image-banner-container-4 {
    margin-left: 15px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 660px) {
  .image-banner-section-container {
    width: 100%;
    margin: 0;
  }
  .image-banners-container {
    width: 100%;
    margin: 0;
  }
  .image-banner-container-1 {
    width: 48%;
    margin-left: 0%;
    margin-right: 4%;
  }
  .image-banner-container-2 {
    margin-left: 0%;
    margin-right: 0%;
    width: 48%;
  }
  .image-banner-container-3 {
    width: 48%;
    margin-left: 0%;
    margin-right: 4%;
  }
  .image-banner-container-4 {
    margin-left: 0%;
    margin-right: 0%;
    width: 48%;
  }
  .image-banner-container-5 {
    width: 48%;
    margin-left: 0%;
    margin-right: 4%;
  }
  .image-banner-container-6 {
    margin-left: 0%;
    margin-right: 0%;
    width: 48%;
  }
  .image-banner {
    height: auto;
    width: 100%;
    max-width: 294px;
  }
  .image-banner-main-icon-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
  .image-banner-main-icon {
    height: 26px;
    width: 26px;
  }
  .image-banner-heading-and-chevron-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .image-banner-heading {
    text-align: center;
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .image-banner-chevron-icon {
    display: none;
  }
  .image-banner-subheading {
    display: none;
  }
}
