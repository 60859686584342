.cta-button-icon-left-solid {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: fit-content;
  height: 50px;
  border-radius: var(--UIborderRadius);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  background-color: var(--brandColour1);
  color: var(--UIcolour0);
  transition: background-color var(--UItransitionTime);
}

.cta-button-icon-left-solid:hover,
.cta-button-icon-left-solid:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.cta-button-icon-left-solid:disabled {
  opacity: 0.6;
}

.cta-button-icon-left-icon-and-label-wrapper {
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.cta-button-icon-left-wrapper-inner-solid {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--brandColour1);
}

.cta-button-icon-left-icon-solid {
  height: 20px;
  width: 20px;
  margin: 0px;
  fill: white;
}

.cta-button-icon-left-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: fit-content;
  height: 50px;
  border-radius: var(--UIborderRadius);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;
  cursor: pointer;
  background-color: var(--UIcolour0);
  border: 1px solid var(--brandColour1);
  color: var(--brandColour1);
  transition: background-color var(--UItransitionTime);
}

.cta-button-icon-left-wrapper-inner-inverted {
  display: flex;
  justify-content: center;
  margin-right: 8px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: white;
  transition: background-color var(--UItransitionTime);
}

.cta-button-icon-left-inverted:hover,
.cta-button-icon-left-inverted:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  outline: 0;
}

.cta-button-icon-left-inverted:hover
  ~ .cta-button-icon-left-wrapper-inner-inverted {
  background: #ccc;
}

.cta-button-icon-left-inverted:disabled {
  opacity: 0.6;
}

.cta-button-icon-left-wrapper-inner-inverted:hover,
.cta-button-icon-left-wrapper-inner-inverted:focus {
  background: var(--brandColour1Dulled);
}

.cta-button-icon-left-inverted:hover .cta-button-icon-left-icon-inverted {
  fill: white;
}

.cta-button-icon-left-inverted:hover
  .cta-button-icon-left-wrapper-inner-inverted {
  background: var(--brandColour1Dulled);
}
.cta-button-icon-left-icon-inverted {
  height: 20px;
  width: 20px;
  margin: 0px;
  fill: var(--brandColour1);
  transition: fill var(--UItransitionTime);
}

.create-new-button {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: var(--brandColour1);
  border: none;
  border-radius: var(--UIborderRadius);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--UIcolour0);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.create-new-button:hover,
.create-new-button:focus {
  background-color: var(--brandColour1Dulled);
  outline: 0;
}

.create-new-button:disabled {
  opacity: 0.6;
}

.create-new-button-inverted {
  -webkit-appearance: none;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: auto;
  height: 50px;
  background-color: var(--UIcolour0);
  border: 1px solid var(--brandColour1);
  border-radius: var(--UIborderRadius);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: var(--brandColour1);
  font-size: 18px;
  cursor: pointer;
  transition: background-color var(--UItransitionTime);
}

.create-new-button-inverted:hover,
.create-new-button-inverted:focus {
  background-color: var(--brandColour1Dulled);
  color: var(--UIcolour0);
  outline: 0;
}

.create-new-button-inverted:disabled {
  opacity: 0.6;
}
