#loading-page-canvas {
  max-width: var(--globalPageWidth);
  background-color: var(--UIcolour0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

#loading-logo-wrapper {
  max-width: 50%;
  margin: auto;
}

.loading-text {
  max-width: 50%;
  font-size: 14px;
  margin-top: 5px;
}

.iframe-loading-text {
  max-width: 50%;
  font-size: 14px;
  margin-top: 10px;
}

.iframe-loading-canvas {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iframe-loading-logo-wrapper {
  max-width: 60%;
  margin: auto;
}