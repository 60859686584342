@font-face {
  font-family: "SF Pro Text";
  src: url("./fonts/SF-Pro-Text-Regular.eot");
  src: local("SF Pro Text"), local("SFProText"),
    url("./fonts/SF-Pro-Text-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/SF-Pro-Text-Regular.otf") format("opentype"),
    url("./fonts/SF-Pro-Text-Regular.woff2") format("woff2"),
    url("./fonts/SF-Pro-Text-Regular.woff") format("woff"),
    url("./fonts/SF-Pro-Text-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProText-Bold";
  src: url("./fonts/SF-Pro-Text-Bold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/SF-Pro-Text-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/SF-Pro-Text-Bold.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/SF-Pro-Text-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/SF-Pro-Text-Bold.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/SF-Pro-Text-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFProText-Semibold";
  src: url("./fonts/SF-Pro-Text-Semibold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/SF-Pro-Text-Semibold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/SF-Pro-Text-Semibold.otf") format("opentype"),
    /* Open Type Font */ url("./fonts/SF-Pro-Text-Semibold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/SF-Pro-Text-Semibold.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/SF-Pro-Text-Semibold.woff2")
      format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

* {
  --globalPageWidth: 960px;
  --globalContentWidth: 900px;
  --responsiveBreakpoint: 600px;
  --headerHeight: 62px;
  --compactHeaderHeight: 44px;
  --footerHeight: 64px;
  --UIcolour0: #ffffff;
  --UIcolour1: #333333;
  --UIcolour2: #6a6a6a;
  --UIcolour3: #cccccc;
  --UIcolour4: #cccccc94;
  --UIcolour5: #ed495a;
  --UIcolour6: #f0f0f0;
  --UIcolour7: #ededed;
  --UIcolour8: #10b9e8;
  --UIcolour9: #ff7f50;
  --UIfontType1: "SF Pro Text", Sans-Serif;
  --UIfontType1SemiBold: "SFProText-Semibold", Sans-Serif;
  --UIborderRadius: 4px;
  --UIimageFadeInTime: 0s;
  --UItransitionTime: 0.15s;
  --brandColour1: #1ec1a1;
  --brandColour1Dulled: #22ba9d;
  --brandColour2: #e5f3f7;
}

* {
  font-family: "SF Pro Text", Sans-Serif;
}

html,
body,
#App {
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

input:focus {
  outline: 0;
}

h1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1SemiBold);
  font-size: 30px;
}

h2 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: var(--UIfontType1);
  font-size: 16px;
  color: var(--UIcolour2);
}
